
import { defineComponent } from 'vue';
import { Actions } from '@/store/enums/VerifyAccountEnums';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'resend-verification-page',
  mounted() {
    this.handleSendVerificationEmail();
  },
  data: () => ({
    sending: false,
  }),
  methods: {
    ...mapActions({
      sendVerificationEmail: Actions.SEND_VERIFICATION_EMAIL,
    }),
    signIn() {
      this.$router.push('/sign-in');
    },
    async handleSendVerificationEmail() {
      if (this.sending) return;
      this.sending = true;

      const queryParams = await this.$route.query;

      const payload = {
        tenant_review_no: queryParams.ref ? queryParams.ref : '',
        target: queryParams.target ? queryParams.target : '',
      };

      return await this.sendVerificationEmail(payload).then(() => {
        this.sending = false;
      });
    },
  },
});
